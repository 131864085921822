import React from 'react';
import styles from './OrderCreationCTAs.module.css';
import Link from 'next/link';
import Image from 'next/image';
import CreateOrderButton from './CreateOrderButton';
import { OrderTypes, getOrderTypeName } from '../../context/OrderTypes';
import { useVesselOptions } from '../../hooks/useVesselOptions';
import { useEditableOrders } from '../../hooks/useEditableOrders';

const OrderCreationCTAs = () => {
    const { availableOrderTypes } = useVesselOptions();
    const { data: editableOrders } = useEditableOrders();

    return (
        <div className={styles.container}>
            <div className={[styles.row, styles.titleRow].join(' ')}>
                <p className={[styles.font, styles.ordersTitleText].join(' ')}>
                    Orders
                </p>
                <Link href="/order">
                    <a className={[styles.font, styles.seeAllText].join(' ')}>
                        See all
                    </a>
                </Link>
            </div>
            {availableOrderTypes.map((orderType, i) => {
                const draftsLocalIdsByTypes = editableOrders
                    ?.filter(
                        (order) =>
                            order.type === orderType && order.status === 0,
                    )
                    ?.flatMap((orderData) => orderData.localOrderId);
                const orderTypeIcon = Object.keys(OrderTypes)[i];

                return (
                    <div
                        key={orderType}
                        className={[styles.row, styles.orderRow].join(' ')}
                    >
                        <div className={styles.orderWithIconContainer}>
                            <Image
                                src={`/gfx/uiIcons/${orderTypeIcon}.svg`}
                                width="22px"
                                height="22px"
                                alt={`${orderType} order icon`}
                            />
                            <p className={styles.orderText}>
                                {getOrderTypeName(orderType)}
                            </p>
                        </div>
                        <CreateOrderButton
                            draftsIds={draftsLocalIdsByTypes ?? []}
                            type={orderType}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default OrderCreationCTAs;

import styles from './VRPerformanceTrendGraph.module.css';
import { CustomDropdownMenu } from '../../CustomDropdownMenu/CustomDropdownMenu';
import { useState } from 'react';
import { DropdownItem } from '../../Dropdown/Dropdown';
import { useVesselVrRates } from '../../../hooks/useVesselVrRates';
import {
    adjustVesselVrRatesToDisplayOnTrendGraph,
    checkIfTrendDataIsEmpty,
} from '../../../db/vesselVrRates';
import InfoIcon from '../../../public/gfx/uiIcons/blueInfoIcon.svg';
import Tooltip from '../../Tooltips/Tooltip';
import { VRPerformanceTrendGraph } from './VRPerformanceTrendGraph';

export const VrPerformanceTrendGraphCard = () => {
    const { vesselVrRates } = useVesselVrRates();

    const vrRatesHistory =
        adjustVesselVrRatesToDisplayOnTrendGraph(
            vesselVrRates?.historicVesselVrRate,
        ) ?? [];

    const isVrRatesHistoryEmpty = checkIfTrendDataIsEmpty(vrRatesHistory);

    const dropdownItems: DropdownItem[] = [
        {
            id: '6months',
            onClick: () => {
                setSelectedItem(dropdownItems[0]);
            },
            content: <>Last 6 months</>,
        },
    ];

    if (!isVrRatesHistoryEmpty) {
        dropdownItems.push({
            id: '1year',
            onClick: () => {
                setSelectedItem(dropdownItems[1]);
            },
            content: <>Last 12 months</>,
        });
    }

    const [selectedItem, setSelectedItem] = useState(dropdownItems[0]);

    const getGraphData = () => {
        if (selectedItem.id === '6months') {
            return vrRatesHistory.slice(6, 12);
        } else return vrRatesHistory;
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.row}>
                    <div className={styles.rowTitle}>
                        <p className={styles.graphTitle}>
                            VR Performance trend
                        </p>
                        {isVrRatesHistoryEmpty ? (
                            <Tooltip
                                additionalTriggerStyles={styles.tooltipButton}
                                tooltipTitle="We can’t calculate your VR yet."
                                tooltipMessage="We currently don’t have enough data to display. As soon as you create orders and complete stocktaking within Gateway you’ll receive monthly VR rates."
                            >
                                <InfoIcon width="16" height="18" />
                            </Tooltip>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className={styles.rowDropdown}>
                        <CustomDropdownMenu
                            items={dropdownItems}
                            selectedItem={selectedItem}
                            disabled={dropdownItems.length <= 1}
                        />
                    </div>
                </div>
                <div className={styles.rowTitle}>
                    <div
                        className={[styles.legendMarkup, styles.blue].join(' ')}
                    ></div>
                    <p className={styles.legendText}>Actual Rate</p>
                    <div
                        className={[
                            styles.legendMarkup,
                            styles.black,
                            styles.legendGap,
                        ].join(' ')}
                    ></div>
                    <p className={styles.legendText}>Budget Rate</p>
                </div>
                <div style={{ width: '100%', height: 250 }}>
                    <VRPerformanceTrendGraph
                        historicVesselVrRates={getGraphData()}
                    />
                </div>
            </div>
        </div>
    );
};

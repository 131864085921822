import styles from './VRPerformanceTrendGraph.module.css';
import { useState } from 'react';
import {
    Area,
    CartesianGrid,
    ComposedChart,
    Line,
    ResponsiveContainer,
    Scatter,
    Tooltip as ChartTooltip,
    TooltipProps,
    XAxis,
} from 'recharts';
import { currencyFormatter } from '../../utils/numberFormat';
import { useVesselMetadata } from '../../../hooks/useVesselMetadata';
import { VesselVrRatesToDisplayTrendGraphData } from '../../../types/vesselVrRates';
import { graphColors } from './graphColors';

interface Props {
    historicVesselVrRates: VesselVrRatesToDisplayTrendGraphData[];
}

export const VRPerformanceTrendGraph: React.FC<Props> = ({
    historicVesselVrRates,
}) => {
    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const [valuesTooltipIsShown, setValuesTooltipIsShown] = useState(false);

    const onDotEnter = () => {
        setValuesTooltipIsShown(true);
    };

    const onDotLeave = () => {
        setValuesTooltipIsShown(false);
    };

    const formatValue = (value: number) => {
        return currencyFormatter(value, vesselCurrency);
    };

    const CustomValueTooltip = ({
        active,
        payload,
    }: TooltipProps<number, string>) => {
        if (active) {
            return (
                <div className={styles.tooltip}>
                    <p>{payload?.[0].payload.month}</p>
                    <p className={styles.vrRateTooltip}>
                        Actual rate: {formatValue(Number(payload?.[0].value))}
                    </p>
                    <p>
                        Budget rate: {formatValue(Number(payload?.[1].value))}
                    </p>
                </div>
            );
        }
        return null;
    };

    return (
        <ResponsiveContainer>
            <ComposedChart
                data={historicVesselVrRates}
                margin={{
                    top: 0,
                    right: 5,
                    left: 5,
                    bottom: 0,
                }}
            >
                <defs>
                    <linearGradient
                        id="gradient"
                        x1="217"
                        y1="0"
                        x2="217"
                        y2="200"
                        gradientUnits="userSpaceOnUse"
                        opacity="0.2"
                    >
                        <stop
                            stopColor={graphColors.blue}
                            offset="5%"
                            stopOpacity={0.4}
                        />
                        <stop
                            offset="1"
                            stopColor={graphColors.blue}
                            stopOpacity={0}
                        />
                    </linearGradient>
                </defs>
                <XAxis
                    dataKey="month"
                    interval="preserveStartEnd"
                    style={{
                        color: 'graphColors.lightGrey',
                        fontSize: '10px',
                        fontWeight: '500',
                        letterSpacing: '-0.2px',
                    }}
                    tickLine={false}
                    axisLine={false}
                />
                <Area
                    dataKey="vrRate"
                    fillOpacity={1}
                    strokeWidth={3}
                    stroke={graphColors.blue}
                    fill="url(#gradient)"
                    dot={false}
                    activeDot={false}
                    tooltipType="none"
                    animationDuration={300}
                />
                <Line
                    dataKey="budgetRate"
                    fillOpacity={1}
                    strokeWidth={3}
                    stroke={graphColors.black}
                    dot={false}
                    activeDot={false}
                    tooltipType="none"
                    animationDuration={300}
                />
                <Scatter
                    dataKey="vrRate"
                    fill="#2760FF"
                    onMouseOver={onDotEnter}
                    onMouseLeave={onDotLeave}
                    animationDuration={200}
                />
                <Scatter
                    dataKey="budgetRate"
                    fill={graphColors.black}
                    onMouseOver={onDotEnter}
                    onMouseLeave={onDotLeave}
                    animationDuration={200}
                />

                <CartesianGrid strokeOpacity={0.3} height={1} />
                <ChartTooltip
                    cursor={false}
                    labelClassName={styles.tooltipLabel}
                    content={
                        valuesTooltipIsShown ? <CustomValueTooltip /> : <></>
                    }
                    offset={0}
                    isAnimationActive={false}
                    formatter={formatValue}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

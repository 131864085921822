import { apiClient } from '../apiClient/apiClient';
import moment from 'moment';
import { db } from './db';
import { HistoricVesselVrRate } from '../apiClient/generated';
import { VesselVrRatesToDisplayTrendGraphData } from '../types/vesselVrRates';

export const fetchAndUpdateVesselVrRates = async () => {
    const vesselMetadata = await db.vesselMetadata.toArray();
    const vesselCompany = vesselMetadata[0].company;
    const fetchDate = moment().format('YYYY-MM');

    const vrRatesResponse = await apiClient.getVesselVr(
        vesselCompany ?? '',
        fetchDate,
    );
    if (vrRatesResponse) {
        await db.vesselVrRates.clear();
        await db.vesselVrRates.put(vrRatesResponse);
    }
    return vrRatesResponse;
};

const getLast12Months = () => {
    const months = moment.monthsShort();
    const currentMonth = new Date().getMonth();

    const slicedMonthsArrayFirstPart = months.slice(currentMonth);
    const slicedMonthsArraySecondPart = months.slice(0, currentMonth);

    if (currentMonth === 0) {
        return months;
    } else {
        return [...slicedMonthsArrayFirstPart, ...slicedMonthsArraySecondPart];
    }
};

export const adjustVesselVrRatesToDisplayOnTrendGraph = (
    vrRatesHistory: HistoricVesselVrRate[] | null | undefined,
) => {
    if (!vrRatesHistory) {
        return;
    }

    const last12Months = getLast12Months();

    const annualTrendData: VesselVrRatesToDisplayTrendGraphData[] =
        last12Months.map((month) => {
            const vrRateForMonth = vrRatesHistory.find(
                (item) =>
                    month.toLowerCase() ===
                    moment(item.month).format('MMM').toLowerCase(),
            );

            return {
                month: month,
                vrRate: vrRateForMonth?.vrRate,
                budgetRate: vrRateForMonth?.budgetRate,
            };
        });

    return annualTrendData;
};

export const checkIfTrendDataIsEmpty = (
    trendData: VesselVrRatesToDisplayTrendGraphData[],
) => {
    const isVrRateAvailable = !trendData.find((item) => {
        return item.vrRate !== undefined;
    });
    const isBudgetRateAvailable = !trendData.find((item) => {
        return item.budgetRate !== undefined;
    });
    return isVrRateAvailable && isBudgetRateAvailable;
};

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styles from './CustomDropdownMenu.module.css';
import Arrow from '../../public/gfx/uiIcons/collapsedArrow.svg';
import { DropdownItem } from '../Dropdown/Dropdown';
import { FC } from 'react';

interface Props {
    items: DropdownItem[];
    selectedItem: DropdownItem;
    disabled?: boolean;
}

export const CustomDropdownMenu: FC<Props> = ({
    items,
    selectedItem,
    disabled,
}) => {
    return (
        <DropdownMenu.Root modal={false}>
            <DropdownMenu.Trigger
                className={styles.trigger}
                disabled={disabled}
            >
                <button
                    className={[
                        styles.dropdownButton,
                        disabled ? styles.dropdownButtonDisabled : '',
                    ].join(' ')}
                >
                    <p>{selectedItem.content}</p>
                    <div>
                        <Arrow stroke="#000" width="24" height="24" />
                    </div>
                </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content
                className={styles.dropdownContent}
                portalled={false}
            >
                {items.map((item: DropdownItem) => {
                    return (
                        <DropdownMenu.DropdownMenuItem
                            key={item.id}
                            className={styles.dropdownElement}
                            onClick={item.onClick}
                        >
                            {item.content}
                        </DropdownMenu.DropdownMenuItem>
                    );
                })}
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
};

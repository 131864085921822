import React from 'react';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import styles from './VesselMetaData.module.css';

const VesselMetaData = () => {
    const {
        data: {
            seaStarContact,
            seaStarEmail,
            seaStarOfficeHours,
            seaStarPhone,
        },
    } = useVesselMetadata();

    const seaStar = {
        contact: seaStarContact,
        email: seaStarEmail,
        hours: seaStarOfficeHours,
        phone: seaStarPhone,
    };

    return (
        <div className={styles.container}>
            <p className={styles.text}>Your Garrets Contact</p>
            <div className={styles.rowWidthPadding}>
                <div className={styles.column}>
                    <div className={styles.row}>
                        <p className={styles.text}>Name</p>
                        <p className={styles.textBold}>{seaStar.contact}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={styles.text}>Phone</p>
                        <p className={styles.textBold}>{seaStar.phone}</p>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.row}>
                        <p className={styles.text}>Email</p>
                        <p className={styles.textBold}>{seaStar.email}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={styles.text}>Office hours</p>
                        <p className={styles.textBold}>{seaStar.hours}</p>
                    </div>
                </div>
                <div className={styles.column}>
                    <p className={styles.text}>
                        Garrets <span>&#174;</span>
                    </p>
                    <p className={styles.nonDisplay}>null</p>
                </div>
            </div>
        </div>
    );
};

export default VesselMetaData;

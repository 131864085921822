import React from 'react';
import styles from './OrdersSection.module.css';
import { useOrders } from '../../hooks/useOrders';
import { OrdersOverviewList } from '../OrdersOverviewComponents/OrdersOverviewList';
import { adjustOrderToDisplayInOrdersList } from '../utils/adjustOrderToDisplayInOrdersList';
import { sortOrdersToDisplayInListByDate } from '../OrdersOverviewComponents/utils';
import { useCashPurchaseOrders } from '../../hooks/useCashPurchaseOrders';
import { adjustCashPurchaseOrderToDisplayInOrdersList } from '../utils/adjustCashPurchaseOrderToDisplayInOrdersList';
import { useFeatureFlags } from '../../context/useFeatureFlags';

const OrdersSection = () => {
    const { data: orders, isValidatingOrders } = useOrders();
    const { cashPurchaseOrders } = useCashPurchaseOrders();
    const { featureFlags } = useFeatureFlags();

    const ordersToDisplay = orders?.map(adjustOrderToDisplayInOrdersList) ?? [];
    const cashPurchaseOrdersToDisplay = featureFlags?.cashPurchase
        ? cashPurchaseOrders?.map(
              adjustCashPurchaseOrderToDisplayInOrdersList,
          ) ?? []
        : [];

    const ordersListToDisplay = sortOrdersToDisplayInListByDate([
        ...ordersToDisplay,
        ...cashPurchaseOrdersToDisplay,
    ]).slice(0, 4);

    return (
        <div className={styles.comingSoonContainer}>
            <div className={styles.topBar}>
                <p className={styles.titleText}>Order Status</p>
            </div>
            <OrdersOverviewList
                orders={ordersListToDisplay}
                isValidating={isValidatingOrders}
                showHeader={false}
                showLastUpdated={false}
            />
        </div>
    );
};

export default OrdersSection;
